import './Promo.css';

const Promo = () => {
  return (
    <section className='promo'>
      <div className='promo__content'>
        <h1 className='promo__title'>Учебный проект студента факультета Веб-разработки.</h1>
      </div>
    </section>
  )
};

export default Promo;
